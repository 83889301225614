{
  "a11y": {
    "loading_page": "Loading page, please wait",
    "loading_titled_page": "Loading {0} page, please wait",
    "locale_changed": "Language changed to {0}",
    "locale_changing": "Changing language, please wait",
    "route_loaded": "Page {0} loaded"
  },
  "account": {
    "avatar_description": "{0}'s avatar",
    "blocked_by": "You're blocked by this user.",
    "blocked_domains": "Blocked domains",
    "blocked_users": "Blocked users",
    "blocking": "Blocked",
    "bot": "BOT",
    "favourites": "Favorites",
    "follow": "Follow",
    "follow_back": "Follow back",
    "follow_requested": "Requested",
    "followers": "Followers",
    "followers_count": "{0} Followers|{0} Follower|{0} Followers",
    "following": "Following",
    "following_count": "{0} Following",
    "follows_you": "Follows you",
    "go_to_profile": "Go to profile",
    "joined": "Joined",
    "moved_title": "has indicated that their new account is now:",
    "muted_users": "Muted users",
    "muting": "Muted",
    "mutuals": "Mutuals",
    "notifications_on_post_disable": "Stop notifying me when {username} posts",
    "notifications_on_post_enable": "Notify me when {username} posts",
    "pinned": "Pinned",
    "posts": "Posts",
    "posts_count": "{0} Posts|{0} Post|{0} Posts",
    "profile_description": "{0}'s profile header",
    "profile_unavailable": "Profile unavailable",
    "request_follow": "Request to follow",
    "unblock": "Unblock",
    "unfollow": "Unfollow",
    "unmute": "Unmute",
    "view_other_followers": "Followers from other instances may not be displayed.",
    "view_other_following": "Following from other instances may not be displayed."
  },
  "action": {
    "apply": "Apply",
    "bookmark": "Bookmark",
    "bookmarked": "Bookmarked",
    "boost": "Boost",
    "boost_count": "{0}",
    "boosted": "Boosted",
    "clear_publish_failed": "Clear publish errors",
    "clear_upload_failed": "Clear file upload errors",
    "close": "Close",
    "compose": "Compose",
    "confirm": "Confirm",
    "edit": "Edit",
    "enter_app": "Enter App",
    "favourite": "Favorite",
    "favourite_count": "{0}",
    "favourited": "Favorited",
    "more": "More",
    "next": "Next",
    "prev": "Prev",
    "publish": "Publish",
    "reply": "Reply",
    "reply_count": "{0}",
    "reset": "Reset",
    "save": "Save",
    "save_changes": "Save changes",
    "sign_in": "Sign in",
    "sign_in_to": "Sign in to {0}",
    "switch_account": "Switch account",
    "vote": "Vote"
  },
  "app_desc_short": "A nimble Mastodon web client",
  "app_logo": "Elk Logo",
  "app_name": "Elk",
  "attachment": {
    "edit_title": "Description",
    "remove_label": "Remove attachment"
  },
  "command": {
    "activate": "Activate",
    "complete": "Complete",
    "compose_desc": "Write a new post",
    "n-people-in-the-past-n-days": "{0} people in the past {1} days",
    "select_lang": "Select language",
    "sign_in_desc": "Add an existing account",
    "switch_account": "Switch to {0}",
    "switch_account_desc": "Switch to another account",
    "toggle_dark_mode": "Toggle dark mode",
    "toggle_zen_mode": "Toggle zen mode"
  },
  "common": {
    "end_of_list": "End of the list",
    "error": "ERROR",
    "in": "in",
    "not_found": "404 Not Found",
    "offline_desc": "Seems like you are offline. Please check your network connection."
  },
  "compose": {
    "draft_title": "Draft {0}",
    "drafts": "Drafts ({v})"
  },
  "confirm": {
    "block_account": {
      "cancel": "Cancel",
      "confirm": "Block",
      "title": "Are you sure you want to block {0}？"
    },
    "block_domain": {
      "cancel": "Cancel",
      "confirm": "Block",
      "title": "Are you sure you want to block {0}？"
    },
    "common": {
      "cancel": "No",
      "confirm": "Yes"
    },
    "delete_list": {
      "cancel": "Cancel",
      "confirm": "Delete",
      "title": "Are you sure you want to delete the \"{0}\" list?"
    },
    "delete_posts": {
      "cancel": "Cancel",
      "confirm": "Delete",
      "title": "Are you sure you want to delete this post?"
    },
    "mute_account": {
      "cancel": "Cancel",
      "confirm": "Mute",
      "title": "Are you sure you want to mute {0}？"
    },
    "show_reblogs": {
      "cancel": "Cancel",
      "confirm": "Show",
      "title": "Are you sure you want to show boosts from {0}？"
    },
    "unfollow": {
      "cancel": "Cancel",
      "confirm": "Unfollow",
      "title": "Are you sure you want to unfollow?"
    }
  },
  "conversation": {
    "with": "with"
  },
  "custom_cards": {
    "stackblitz": {
      "lines": "Lines {0}",
      "open": "Open",
      "snippet_from": "Snippet from {0}"
    }
  },
  "error": {
    "account_not_found": "Account {0} not found",
    "explore-list-empty": "Nothing is trending right now. Check back later!",
    "file_size_cannot_exceed_n_mb": "File size cannot exceed {0}MB",
    "sign_in_error": "Cannot connect to the server.",
    "status_not_found": "Post not found",
    "unsupported_file_format": "Unsupported file format"
  },
  "help": {
    "build_preview": {
      "desc1": "You are currently viewing a preview version of Elk from the community - {0}.",
      "desc2": "It may contains unreviewed or even malicious changes.",
      "desc3": "Don't log in with your real account.",
      "title": "Preview deploy"
    },
    "desc_highlight": "Expect some bugs and missing features here and there.",
    "desc_para1": "Thanks for your interest in trying out Elk, our work-in-progress Mastodon web client!",
    "desc_para2": "we are working hard on the development and improving it over time.",
    "desc_para3": "To boost development, you can sponsor the Team through GitHub Sponsors. We hope you enjoy Elk!",
    "desc_para4": "Elk is Open Source. If you'd like to help with testing, giving feedback, or contributing,",
    "desc_para5": "reach out to us on GitHub",
    "desc_para6": "and get involved.",
    "footer_team": "The Elk Team",
    "title": "Elk is in Preview!"
  },
  "language": {
    "search": "Search"
  },
  "list": {
    "add_account": "Add account to list",
    "cancel_edit": "Cancel editing",
    "clear_error": "Clear error",
    "create": "Create",
    "delete": "Delete this list",
    "delete_error": "There was an error while deleting the list",
    "edit": "Edit this list",
    "edit_error": "There was an error while updating the list",
    "error": "There was an error while creating the list",
    "error_prefix": "Error: ",
    "list_title_placeholder": "List title",
    "modify_account": "Modify lists with account",
    "remove_account": "Remove account from list",
    "save": "Save changes"
  },
  "menu": {
    "block_account": "Block {0}",
    "block_domain": "Block domain {0}",
    "copy_link_to_post": "Copy link to this post",
    "copy_original_link_to_post": "Copy original link to this post",
    "delete": "Delete",
    "delete_and_redraft": "Delete & re-draft",
    "direct_message_account": "Direct message {0}",
    "edit": "Edit",
    "hide_reblogs": "Hide boosts from {0}",
    "mention_account": "Mention {0}",
    "mute_account": "Mute {0}",
    "mute_conversation": "Mute this post",
    "open_in_original_site": "Open in original site",
    "pin_on_profile": "Pin on profile",
    "share_post": "Share this post",
    "show_favourited_and_boosted_by": "Show who favourited and boosted",
    "show_reblogs": "Show boosts from {0}",
    "show_untranslated": "Show untranslated",
    "toggle_theme": {
      "dark": "Toggle dark mode",
      "light": "Toggle light mode"
    },
    "translate_post": "Translate post",
    "unblock_account": "Unblock {0}",
    "unblock_domain": "Unblock domain {0}",
    "unmute_account": "Unmute {0}",
    "unmute_conversation": "Unmute this post",
    "unpin_on_profile": "Unpin on profile"
  },
  "nav": {
    "back": "Go back",
    "blocked_domains": "Blocked domains",
    "blocked_users": "Blocked users",
    "bookmarks": "Bookmarks",
    "built_at": "Built {0}",
    "compose": "Compose",
    "conversations": "Conversations",
    "explore": "Explore",
    "favourites": "Favorites",
    "federated": "Federated",
    "home": "Home",
    "list": "List",
    "lists": "Lists",
    "local": "Local",
    "muted_users": "Muted users",
    "notifications": "Notifications",
    "privacy": "Privacy",
    "profile": "Profile",
    "search": "Search",
    "select_feature_flags": "Toggle Feature Flags",
    "select_font_size": "Font Size",
    "select_language": "Display Language",
    "settings": "Settings",
    "show_intro": "Show intro",
    "toggle_theme": "Toggle Theme",
    "zen_mode": "Zen Mode"
  },
  "notification": {
    "favourited_post": "favorited your post",
    "followed_you": "followed you",
    "followed_you_count": "{0} people followed you|{0} person followed you|{0} people followed you",
    "missing_type": "MISSING notification.type:",
    "reblogged_post": "reblogged your post",
    "request_to_follow": "requested to follow you",
    "signed_up": "signed up",
    "update_status": "updated their post"
  },
  "placeholder": {
    "content_warning": "Write your warning here",
    "default_1": "What is on your mind?",
    "reply_to_account": "Reply to {0}",
    "replying": "Replying",
    "the_thread": "the thread"
  },
  "pwa": {
    "dismiss": "Dismiss",
    "install": "Install",
    "install_title": "Install Elk",
    "title": "New Elk update available!",
    "update": "Update",
    "update_available_short": "Update Elk",
    "webmanifest": {
      "canary": {
        "description": "A nimble Mastodon web client (canary)",
        "name": "Elk (canary)",
        "short_name": "Elk (canary)"
      },
      "dev": {
        "description": "A nimble Mastodon web client (dev)",
        "name": "Elk (dev)",
        "short_name": "Elk (dev)"
      },
      "preview": {
        "description": "A nimble Mastodon web client (preview)",
        "name": "Elk (preview)",
        "short_name": "Elk (preview)"
      },
      "release": {
        "description": "A nimble Mastodon web client",
        "name": "Elk",
        "short_name": "Elk"
      }
    }
  },
  "search": {
    "search_desc": "Search for people & hashtags",
    "search_empty": "Could not find anything for these search terms"
  },
  "settings": {
    "about": {
      "built_at": "Built",
      "label": "About",
      "meet_the_team": "Meet the team",
      "sponsor_action": "Sponsor us",
      "sponsor_action_desc": "To support the team developing Elk",
      "sponsors": "Sponsors",
      "sponsors_body_1": "Elk is made possible thanks the generous sponsoring and help of:",
      "sponsors_body_2": "And all the companies and individuals sponsoring Elk Team and the members.",
      "sponsors_body_3": "If you're enjoying the app, consider sponsoring us:",
      "version": "Version"
    },
    "account_settings": {
      "description": "Edit your account settings in Mastodon UI",
      "label": "Account settings"
    },
    "interface": {
      "color_mode": "Color Mode",
      "dark_mode": "Dark",
      "default": " (default)",
      "font_size": "Font Size",
      "label": "Interface",
      "light_mode": "Light",
      "system_mode": "System",
      "theme_color": "Theme Color"
    },
    "language": {
      "display_language": "Display Language",
      "label": "Language",
      "translations": {
        "add": "Add",
        "choose_language": "Choose language",
        "heading": "Translations",
        "hide_specific": "Hide specific translations",
        "remove": "Remove"
      }
    },
    "notifications": {
      "label": "Notifications",
      "notifications": {
        "label": "Notifications settings"
      },
      "push_notifications": {
        "alerts": {
          "favourite": "Favorites",
          "follow": "New followers",
          "mention": "Mentions",
          "poll": "Polls",
          "reblog": "Reblog your post",
          "title": "What notifications to receive?"
        },
        "description": "Receive notifications even when you are not using Elk.",
        "instructions": "Don't forget to save your changes using @:settings.notifications.push_notifications.save_settings button!",
        "label": "Push notifications settings",
        "policy": {
          "all": "From anyone",
          "followed": "Of people I follow",
          "follower": "Of people who follow me",
          "none": "From no one",
          "title": "Who can I receive notifications from?"
        },
        "save_settings": "Save settings",
        "subscription_error": {
          "clear_error": "Clear error",
          "invalid_vapid_key": "The VAPID public key seems to be invalid.",
          "permission_denied": "Permission denied: enable notifications in your browser.",
          "repo_link": "Elk's repository in Github",
          "request_error": "An error occurred while requesting the subscription, try again and if the error persists, please report the issue to the Elk repository.",
          "title": "Could not subscribe to push notifications",
          "too_many_registrations": "Due to browser limitations, Elk cannot use the push notifications service for multiple accounts on different servers. You should unsubscribe from push notifications on another account and try again.",
          "vapid_not_supported": "Your browser supports Web Push Notifications, but does not seem to implement the VAPID protocol."
        },
        "title": "Push notifications settings",
        "undo_settings": "Undo changes",
        "unsubscribe": "Disable push notifications",
        "unsupported": "Your browser does not support push notifications.",
        "warning": {
          "enable_close": "Close",
          "enable_description": "To receive notifications when Elk is not open, enable push notifications. You can control precisely what types of interactions generate push notifications via the \"@:settings.notifications.show_btn{'\"'} button above once enabled.",
          "enable_description_desktop": "To receive notifications when Elk is not open, enable push notifications. You can control precisely what types of interactions generate push notifications in \"Settings > Notifications > Push notifications settings\" once enabled.",
          "enable_description_mobile": "You can also access the settings using the navigation menu \"Settings > Notifications > Push notification settings\".",
          "enable_description_settings": "To receive notifications when Elk is not open, enable push notifications. You will be able to control precisely what types of interactions generate push notifications on this same screen once you enable them.",
          "enable_desktop": "Enable push notifications",
          "enable_title": "Never miss anything",
          "re_auth": "It seems that your server does not support push notifications. Try sign out and sign in again, if this message still appears contact your server administrator."
        }
      },
      "show_btn": "Go to notifications settings",
      "under_construction": "Under construction"
    },
    "notifications_settings": "Notifications",
    "preferences": {
      "enable_autoplay": "Enable Autoplay",
      "enable_pinch_to_zoom": "Enable pinch to zoom",
      "github_cards": "GitHub Cards",
      "grayscale_mode": "Grayscale mode",
      "hide_account_hover_card": "Hide account hover card",
      "hide_alt_indi_on_posts": "Hide alt indicator on posts",
      "hide_boost_count": "Hide boost count",
      "hide_favorite_count": "Hide favorite count",
      "hide_follower_count": "Hide follower count",
      "hide_reply_count": "Hide reply count",
      "hide_translation": "Hide translation",
      "hide_username_emojis": "Hide username emojis",
      "hide_username_emojis_description": "Hides emojis from usernames in timelines. Emojis will still be visible in their profiles.",
      "label": "Preferences",
      "title": "Experimental Features",
      "user_picker": "User Picker",
      "virtual_scroll": "Virtual Scrolling",
      "wellbeing": "Wellbeing"
    },
    "profile": {
      "appearance": {
        "bio": "Bio",
        "description": "Edit avatar, username, profile, etc.",
        "display_name": "Display name",
        "label": "Appearance",
        "profile_metadata": "Profile metadata",
        "profile_metadata_desc": "You can have up to {0} items displayed as a table on your profile",
        "title": "Edit profile"
      },
      "featured_tags": {
        "description": "People can browse your public posts under these hashtags.",
        "label": "Featured hashtags"
      },
      "label": "Profile"
    },
    "select_a_settings": "Select a setting",
    "users": {
      "export": "Export User Tokens",
      "import": "Import User Tokens",
      "label": "Logged in users"
    }
  },
  "share-target": {
    "description": "Elk can be configured so that you can share content from other applications, simply install Elk on your device or computer and sign in.",
    "hint": "In order to share content with Elk, Elk must be installed and you must be signed in.",
    "title": "Share with Elk"
  },
  "state": {
    "attachments_exceed_server_limit": "The number of attachments exceeded the limit per post.",
    "attachments_limit_error": "Limit per post exceeded",
    "edited": "(Edited)",
    "editing": "Editing",
    "loading": "Loading...",
    "publish_failed": "Publish failed",
    "publishing": "Publishing",
    "upload_failed": "Upload failed",
    "uploading": "Uploading..."
  },
  "status": {
    "boosted_by": "Boosted By",
    "edited": "Edited {0}",
    "favourited_by": "Favorited By",
    "filter_hidden_phrase": "Filtered by",
    "filter_removed_phrase": "Removed by filter",
    "filter_show_anyway": "Show anyway",
    "img_alt": {
      "ALT": "ALT",
      "desc": "Description",
      "dismiss": "Dismiss",
      "read": "Read {0} description"
    },
    "poll": {
      "count": "{0} votes|{0} vote|{0} votes",
      "ends": "ends {0}",
      "finished": "finished {0}"
    },
    "reblogged": "{0} reblogged",
    "replying_to": "Replying to {0}",
    "show_full_thread": "Show Full thread",
    "someone": "someone",
    "spoiler_show_less": "Show less",
    "spoiler_show_more": "Show more",
    "thread": "Thread",
    "try_original_site": "Try original site"
  },
  "status_history": {
    "created": "created {0}",
    "edited": "edited {0}"
  },
  "tab": {
    "accounts": "Accounts",
    "for_you": "For you",
    "hashtags": "Hashtags",
    "list": "List",
    "media": "Media",
    "news": "News",
    "notifications_all": "All",
    "notifications_mention": "Mention",
    "posts": "Posts",
    "posts_with_replies": "Posts & Replies"
  },
  "tag": {
    "follow": "Follow",
    "follow_label": "Follow {0} tag",
    "unfollow": "Unfollow",
    "unfollow_label": "Unfollow {0} tag"
  },
  "time_ago_options": {
    "day_future": "in 0 days|tomorrow|in {n} days",
    "day_past": "0 days ago|yesterday|{n} days ago",
    "hour_future": "in 0 hours|in 1 hour|in {n} hours",
    "hour_past": "0 hours ago|1 hour ago|{n} hours ago",
    "just_now": "just now",
    "minute_future": "in 0 minutes|in 1 minute|in {n} minutes",
    "minute_past": "0 minutes ago|1 minute ago|{n} minutes ago",
    "month_future": "in 0 months|next month|in {n} months",
    "month_past": "0 months ago|last month|{n} months ago",
    "second_future": "just now|in {n} second|in {n} seconds",
    "second_past": "just now|{n} second ago|{n} seconds ago",
    "short_day_future": "in {n}d",
    "short_day_past": "{n}d",
    "short_hour_future": "in {n}h",
    "short_hour_past": "{n}h",
    "short_minute_future": "in {n}min",
    "short_minute_past": "{n}min",
    "short_month_future": "in {n}mo",
    "short_month_past": "{n}mo",
    "short_second_future": "in {n}s",
    "short_second_past": "{n}s",
    "short_week_future": "in {n}w",
    "short_week_past": "{n}w",
    "short_year_future": "in {n}y",
    "short_year_past": "{n}y",
    "week_future": "in 0 weeks|next week|in {n} weeks",
    "week_past": "0 weeks ago|last week|{n} weeks ago",
    "year_future": "in 0 years|next year|in {n} years",
    "year_past": "0 years ago|last year|{n} years ago"
  },
  "timeline": {
    "show_new_items": "Show {v} new items|Show {v} new item|Show {v} new items",
    "view_older_posts": "Older posts from other instances may not be displayed."
  },
  "title": {
    "federated_timeline": "Federated Timeline",
    "local_timeline": "Local Timeline"
  },
  "tooltip": {
    "add_content_warning": "Add content warning",
    "add_emojis": "Add emojis",
    "add_media": "Add images, a video or an audio file",
    "add_publishable_content": "Add content to publish",
    "change_content_visibility": "Change content visibility",
    "change_language": "Change language",
    "emoji": "Emoji",
    "explore_links_intro": "These news stories are being talked about by people on this and other servers of the decentralized network right now.",
    "explore_posts_intro": "These posts from this and other servers in the decentralized network are gaining traction on this server right now.",
    "explore_tags_intro": "These hashtags are gaining traction among people on this and other servers of the decentralized network right now.",
    "open_editor_tools": "Editor tools",
    "publish_failed": "Close failed messages at the top of editor to republish posts",
    "toggle_bold": "Toggle bold",
    "toggle_code_block": "Toggle code block",
    "toggle_italic": "Toggle italic"
  },
  "user": {
    "add_existing": "Add an existing account",
    "server_address_label": "Mastodon Server Address",
    "sign_in_desc": "Sign in to follow profiles or hashtags, favorite, share and reply to posts, or interact from your account on a different server.",
    "sign_in_notice_title": "Viewing {0} public data",
    "sign_out_account": "Sign out {0}",
    "tip_no_account": "If you don't have a Mastodon account yet, {0}.",
    "tip_register_account": "pick your server and register one"
  },
  "visibility": {
    "direct": "Direct",
    "direct_desc": "Visible for mentioned users only",
    "private": "Followers only",
    "private_desc": "Visible for followers only",
    "public": "Public",
    "public_desc": "Visible for all",
    "unlisted": "Unlisted",
    "unlisted_desc": "Visible for all, but opted-out of discovery features"
  }
}
